<template>
    <div id="dataServiceManagement">
        <public-header class="public-header"></public-header>
        <div class="main-content flex">
            <left-menu
                class="left-menu"
                :menus="menus"
                :mainMenuIndex="mainMenuIndex"
            ></left-menu>
            <div class="flex-1">
                <router-view class="main"></router-view>
            </div>
        </div>
    </div>
</template>
<script>
import info_mixin from "./info_mixin";
export default {
    mixins: [info_mixin],
    name: "dataServiceManagement",
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>
<style lang="scss" scoped>
#dataServiceManagement {
    height: 100%;
    width: 100%;
    position: relative;
    .main-content {
        height: calc(100% - 77px);
        background-color: var(--bodyBgColor);
        .public-header {
            flex: none;
        }
        .main {
            width: 100%;
            height: calc(100% - 30px);
        }
    }
}
</style>
