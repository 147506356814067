import LeftMenu from "@comp/left_menu.vue";
import PublicHeader from "@comp/public_header.vue";
export default {
    name: "dataService_management",
    data() {
        return {
            systems: [],
            menus: [
                {
                    id: "5001",
                    name: "设备监控",
                    routerName: "equipment_state",
                    // bgPicture: "menu/jr_equipment_state"
                    className: "icon-yunhangzhuangtai-01",
                },
                // {
                //     name: "共享服务状态",
                //     routerName: "service_state",
                //     bgPicture: "menu/basic-data"
                // },
                {
                    id: "5002",
                    name: "数据管理",
                    routerName: "equipmentdata_management",
                    // bgPicture: "menu/jr_equipmentdata_management"
                    className: "icon-shujuguanli-01",
                },
                // {
                //     name: "共享服务数据",
                //     routerName: "servicedata_management",
                //     bgPicture: "menu/data-statistics"
                // },
                {
                    id: "5003",
                    name: "配置管理",
                    routerName: "equipment_configuration",
                    // bgPicture: "menu/jr_equipment_configuration"
                    className: "icon-peizhiguanli-01",
                },
                // {
                //     name: "服务配置",
                //     routerName: "service_configuration",
                //     bgPicture: "menu/data-statistics"
                // },
                {
                    id: "5004",
                    name: "设备管理",
                    routerName: "equipment_management",
                    // bgPicture: "menu/jr_equipment_management"
                    className: "icon-shebeiguanli-01",
                },
                {
                    id: "5005",
                    name: "地图服务管理",
                    routerName: "mapservice_management",
                    // bgPicture: "menu/jr_mapservice_management"
                    className: "icon-ditufuwuguanli-01",
                },
                // {
                //     id: "5006",
                //     name: "服务监控",
                //     routerName: "service_manage",
                //     bgPicture: "menu/jr_equipment_management",
                // },
                // {
                //     id: "5007",
                //     name: "服务管理",
                //     routerName: "service_manage",
                //     bgPicture: "menu/jr_mapservice_management",
                // },
            ],
            defaultIndex: 0,
            mainMenuIndex: "50",
        };
    },
    components: {
        LeftMenu,
        PublicHeader,
    },
    created() {
        let routeName = this.$route.name;
        switch (routeName) {
            case "equipment_state":
                this.defaultIndex = 0;
                break;
            default:
                if (routeName.includes("equipmentdata")) {
                    this.defaultIndex = 1;
                } else if (routeName.includes("equipconfig")) {
                    this.defaultIndex = 2;
                } else if (routeName.includes("equipmanage")) {
                    this.defaultIndex = 3;
                } else if (routeName.includes("mapservice_manage")) {
                    this.defaultIndex = 4;
                }
                break;
        }
    },
};